<template>
  <table :class="$style.table">
    <thead :class="$style.header" no-gutters>
      <tr :class="$style.row">
        <th
          v-for="column in columns"
          :key="column"
          :class="$style.col"
          :id="column.id"
          :style="{ width: column.width }"
        >
          <v-sheet :class="$style.head" color="secondary">
            <div v-if="column.checked !== undefined">
              <label
                class="my-2 px-2"
                :class="$style.checkboxLabel"
                :style="[{ textAlign: column.align }, column.style]"
              >
                <input
                  type="checkbox"
                  :class="$style.checkbox"
                  @change="onClickCell(column, columns)"
                  v-model="column.checked"
                />{{ column.text }}
              </label>
            </div>
            <div
              v-else
              class="my-2 px-2"
              @click="onClickCell(column, columns)"
              :class="$style.label"
              :style="[{ textAlign: column.align }, column.style]"
            >
              <v-icon v-if="column.icon" :icon="column.icon"></v-icon>
              <span v-if="column.html" v-html="column.html"></span>
              <span v-else>{{ column.text }}</span>
            </div>
          </v-sheet>
        </th>
      </tr>
    </thead>

    <tbody v-if="data.length" :class="$style.body">
      <tr v-for="row in data" :key="row" :class="$style.list">
        <td
          v-for="cell in row"
          :id="cell.id"
          :key="cell"
          class="pa-2"
          :class="$style.td"
          :style="[{ textAlign: cell.align }, cell.style]"
        >
          <label
            v-if="cell.checked !== undefined"
            :class="$style.checkboxLabel"
            :style="[{ textAlign: cell.align }, cell.style]"
          >
            <input
              type="checkbox"
              :class="$style.checkbox"
              @change="onClickCell(cell, row)"
              v-model="cell.checked"
            />{{ cell.text }}
          </label>
          <div v-else @click="onClickCell(cell, row)">
            <v-icon v-if="cell.icon" :icon="cell.icon"></v-icon>
            <span v-if="cell.html" v-html="cell.html"></span>
            <span v-else>{{ cell.text }}</span>
          </div>
        </td>
      </tr>
    </tbody>

    <tbody v-if="errors.length">
      <tr v-for="error in errors" :key="error">
        <td :colspan="columns.length" :class="$style.error">{{ error }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue'
import { validator, tableKey, TableDataCell } from '@/store/module/table'

export default defineComponent({
  name: 'Table',
  props: {
    tableKey: {
      validator,
    },
  },
  setup(props) {
    const { columns, data, errors } = tableKey(props.tableKey)
    const onClickCell = (cell: TableDataCell, row: TableDataCell[]) => {
      if (cell.click) {
        cell.click(cell, row)
      }
    }

    return { columns, data, errors, onClickCell }
  },
})
</script>

<style module lang="scss">
@import '~vuetify/lib/styles/styles.sass';
// $container-padding-x
// @media (max-width: 450px) {
//   // @media #{map-get($display-breakpoints, 'sm-and-down')} {
//   display: inline-flex;
// }
.table {
  width: 100%;
  overflow: scroll;
  padding: 0;
  border-collapse: collapse;
  // border-width: thin;
  // border-style: solid;
  // border-color: rgba(var(--v-border-color), var(--v-border-opacity));
}
.header {
  position: sticky;
  top: 0;

  .row {
    @include elevation(1);
  }
  .col {
    white-space: nowrap;
    background-color: theme-color('secondary');
    // border-width: thin;
    // border-style: solid;
    // border-color: transparent;
    // border-left: none;
    // border-right: none;
    // border-top-color: rgba(
    //   var(--v-border-light-color),
    //   var(--v-border-light-opacity)
    // );

    &:first-child .head {
      border-left: none;
    }

    &:last-child .head {
      border-right: none;
    }
  }
}

.head {
  text-align: left;
  border-left-width: thin;
  border-left-style: solid;
  border-left-color: rgba(
    var(--v-border-light-color),
    var(--v-border-light-opacity)
  );

  border-right-width: thin;
  border-right-style: solid;
  border-right-color: rgba(var(--v-border-color), var(--v-border-dark-opacity));
  .label {
    display: block;
    user-select: none;
    font-weight: bold;
    white-space: nowrap;
  }
}

.list {
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: rgba(var(--v-border-color), var(--v-border-opacity));

  > .td {
    white-space: nowrap;
  }
}

.checkbox {
  width: 1.5em;
  height: 1.5em;
}
.checkboxLabel {
  width: 1.5em;
  height: 1.5em;
  display: block;
}
.body {
  background: rgb(var(--v-theme-background));
}
.error {
  background: rgb(var(--v-theme-background));
  padding: 16px;
}

.loading {
  text-align: center;
}
</style>
