import { inject, InjectionKey, onMounted, provide, reactive } from 'vue'

export type Align = 'left' | 'center' | 'right'
export type Variant = 'checkbox'

export interface TableDataCell {
  // internal value
  id?: string

  text?: string
  html?: string

  align?: Align
  // css style
  style?: string
  // % or px
  width?: string

  // v-icon
  icon?: string

  click?: CellClickHandler
  checked?: boolean
}

export type CellClickHandler = (
  cell: TableDataCell,
  row: TableDataCell[],
) => void

type ColumnsInjectionKey = InjectionKey<TableDataCell[]>
type DataInjectionKey = InjectionKey<TableDataCell[][]>
type ErrorsInjectionKey = InjectionKey<string[]>

export const defaultColumnsKey: ColumnsInjectionKey = Symbol()
export const defaultDataKey: DataInjectionKey = Symbol()
export const defaultErrorsKey: ErrorsInjectionKey = Symbol()

export type KeyPair = [
  ColumnsInjectionKey,
  DataInjectionKey,
  ErrorsInjectionKey,
]

export interface TableProvidedData {
  columns: ReadonlyArray<TableDataCell>
  data: ReadonlyArray<ReadonlyArray<TableDataCell>>
}

export interface TableStructure {
  columns: TableDataCell[]
  data: TableDataCell[][]
  errors: string[]
}

export const bindKeyPair = (
  columns: TableDataCell[] = [],
  data: TableDataCell[][] = [],
  errors: string[] = [],
): { keys: KeyPair; table: TableStructure } => {
  const keys: KeyPair = [Symbol('columns'), Symbol('data'), Symbol('errors')]
  const rc = reactive(columns)
  const rd = reactive(data)
  const re = reactive(errors)

  provide(keys[0], rc)
  provide(keys[1], rd)
  provide(keys[2], re)

  return { keys, table: { columns: rc, data: rd, errors: re } }
}

export const validator = (value: unknown): value is KeyPair =>
  value instanceof Array &&
  value.length == 3 &&
  typeof value[0] == 'symbol' &&
  typeof value[1] == 'symbol' &&
  typeof value[2] == 'symbol'

export const tableKey = (value: unknown) => {
  const keys = validator(value)
    ? value
    : [defaultColumnsKey, defaultDataKey, defaultErrorsKey]
  const columns = inject(keys[0])
  const data = inject(keys[1])
  const errors = inject(keys[2])
  if (!columns || !data || !errors) {
    throw new Error('Empty columns or data or errors')
  }

  return { columns, data, errors }
}

type TableData = {
  columns: TableDataCell[]
  data: TableDataCell[][]
  errors: string[]
}

export abstract class Table {
  // リストのページネーショントークン
  protected nextToken?: string
  // ページリクエスト中かどうか
  protected pageRequesting = false
  // テーブルのスクロール位置
  protected tableScrollTop = 0
  // テーブルデータ
  protected table?: TableData
  // テーブルカラム
  protected columns: TableDataCell[] = []

  /**
   * Setup component
   *
   * @param targetParentElementId スクロールさせるテーブル要素の親要素のid
   */
  setup(targetParentElementId?: string) {
    const data = this.table ? this.table.data : []
    const { keys, table } = bindKeyPair(this.columns, data)
    this.table = table

    onMounted(() => {
      if (!this.hasData()) {
        this.request()
        return
      }

      // 画面復帰時にユーザーリストテーブルのスクロール位置を復元
      if (targetParentElementId) {
        const target = document.getElementById(targetParentElementId)
        if (target && this.tableScrollTop) {
          target.scrollTo(0, this.tableScrollTop)
        }
      }
    })

    onMounted(() => {
      // 親要素のスクロールにより次ページがあれば読み込みする
      if (targetParentElementId) {
        const target = document.getElementById(targetParentElementId)
        target?.addEventListener('scroll', async (event) => {
          await this.onScroll(event)
        })
      }
    })

    return keys
  }

  reset() {
    this.nextToken = undefined
    this.tableScrollTop = 0
    if (this.table) {
      this.table.data = []
    }
  }

  async reload() {
    this.reset()
    await this.request()
  }

  protected abstract request(): Promise<void>

  private hasData() {
    return this.table?.data ? this.table.data.length > 0 : false
  }

  private async onScroll(event: Event) {
    const target = event.target as HTMLElement
    const pos = target.scrollTop + target.clientHeight
    const nextThreshold = 0.5
    const current = pos / target.scrollHeight

    // 画面復帰時にスクロールの位置を復元するために値を保持する
    this.tableScrollTop = target.scrollTop

    if (!this.pageRequesting && this.nextToken && current > nextThreshold) {
      await this.request()
    }
  }
}
