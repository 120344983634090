import { render } from "./Overlay.vue?vue&type=template&id=106b33d2"
import script from "./Overlay.vue?vue&type=script&lang=ts"
export * from "./Overlay.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./Overlay.vue?vue&type=style&index=0&id=106b33d2&module=true&lang=scss"
cssModules["$style"] = style0
import "./Overlay.vue?vue&type=style&index=1&id=106b33d2&lang=scss"
script.render = render

export default script