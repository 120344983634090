
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  name: 'Overlay',
  props: {
    attach: {
      type: String,
      default: 'body',
    },
    background: {
      type: String,
      default: 'rgba(0, 0, 0, 0.3)',
    },
  },
  setup(props) {
    const root = ref<HTMLElement>()
    const inBody = props.attach == 'body'

    onMounted(() => {
      if (!root.value) return

      root.value.style.background = props.background

      if (inBody) {
        document.querySelector('html')?.classList.add('--overlayed--')
        document.body.appendChild(root.value)
      }
    })

    onUnmounted(() => {
      if (!root.value) return

      if (inBody) {
        document.body.removeChild(root.value)
        document.querySelector('html')?.classList.remove('--overlayed--')
      }
    })

    return { root }
  },
})
