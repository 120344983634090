<template>
  <div ref="root" :class="$style.overlay">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  name: 'Overlay',
  props: {
    attach: {
      type: String,
      default: 'body',
    },
    background: {
      type: String,
      default: 'rgba(0, 0, 0, 0.3)',
    },
  },
  setup(props) {
    const root = ref<HTMLElement>()
    const inBody = props.attach == 'body'

    onMounted(() => {
      if (!root.value) return

      root.value.style.background = props.background

      if (inBody) {
        document.querySelector('html')?.classList.add('--overlayed--')
        document.body.appendChild(root.value)
      }
    })

    onUnmounted(() => {
      if (!root.value) return

      if (inBody) {
        document.body.removeChild(root.value)
        document.querySelector('html')?.classList.remove('--overlayed--')
      }
    })

    return { root }
  },
})
</script>

<style module lang="scss">
@import '~vuetify/lib/styles/styles.sass';
.overlay {
  position: absolute;
  flex: 1 1 auto;
  display: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.--overlayed-- {
  position: relative;
  overflow: hidden !important;
  width: 100vw !important;
  height: 100vh !important;
}
</style>
