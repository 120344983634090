// import { GraphQLResult } from '@aws-amplify/api'
import { GraphQLError as GQLError } from 'graphql'
import * as Types from './types'

export * as Types from './types'

interface GraphQLError extends GQLError {
  errorInfo?: string | null
  errorType?: string
  location?: {
    column: number
    line: number
    sourceName?: string | null
  }[]
}

export interface GraphQLResult<T> {
  data?: T
  errors?: GraphQLError[]
  extensions?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGraphQLError = <T>(err: any): err is GraphQLResult<T> =>
  'data' in err && 'errors' in err

export type GraphQLQueryResult<K extends keyof Types.Query> = Promise<
  GraphQLResult<Record<K, Types.Query[K]>>
>

export type GraphQLMutationResult<K extends keyof Types.Mutation> = Promise<
  GraphQLResult<Record<K, Types.Mutation[K]>>
>
