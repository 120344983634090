
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue'
import { validator, tableKey, TableDataCell } from '@/store/module/table'

export default defineComponent({
  name: 'Table',
  props: {
    tableKey: {
      validator,
    },
  },
  setup(props) {
    const { columns, data, errors } = tableKey(props.tableKey)
    const onClickCell = (cell: TableDataCell, row: TableDataCell[]) => {
      if (cell.click) {
        cell.click(cell, row)
      }
    }

    return { columns, data, errors, onClickCell }
  },
})
